// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".box_box_2jjDp {\n}\n", ""]);
// Exports
exports.locals = {
	"box": "box_box_2jjDp"
};
module.exports = exports;
