// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".direction-picker_button-row_1DF8U {\n    justify-content: center;\n    margin-bottom: 1rem;\n}\n", ""]);
// Exports
exports.locals = {
	"button-row": "direction-picker_button-row_1DF8U",
	"buttonRow": "direction-picker_button-row_1DF8U"
};
module.exports = exports;
