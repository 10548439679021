const JSZip = require('jszip');
function handleUnzip (zipData){
  const jszip = new JSZip();
  return new Promise((resolve, reject) => {
    jszip.loadAsync(zipData).then((zipFiles) => {
      const filePromises = [];
      zipFiles.forEach((relativePath, file) => {
        if (file.dir) return;
        if (!relativePath.startsWith('__MACOSX/') && !relativePath.includes('.DS_Store')) {
          const promise = zipFiles.file(relativePath)?.async('uint8array').then(function (content) {
            // 将解压后的文件内容转换为 Blob 对象
            const blob = new Blob([content]);
            // 创建一个 File 对象
            const fileName = relativePath; // 使用文件相对路径作为文件名
            const fileBlob = new File([blob], fileName, { type: 'application/octet-stream' });
            return fileBlob;
          });
          filePromises.push(promise);
        }
      });
      // 等待所有文件都转换为 File 对象
      resolve(Promise.all(filePromises))
    })
  })
}
function handleZip (fileObj) {
  const zip = new JSZip()
  for (const key in fileObj) {
    if (fileObj[key]) {
      zip.file(key, fileObj[key])
    }
  }
  return zip.generateAsync({type:"blob"})
}
module.exports = {
  handleUnzip,
  handleZip
}