// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n.target-pane_target-pane_3S5E6 {\n    /* Makes columns for the sprite library selector + and the stage selector */\n    display: flex;\n    flex-direction: row;\n    flex-grow: 1;\n}\n\n.target-pane_stage-selector-wrapper_qekSW {\n    display: flex;\n    flex-basis: 72px;\n    flex-shrink: 0;\n}\n\n[dir=\"ltr\"] .target-pane_stage-selector-wrapper_qekSW {\n    margin-left: calc(0.5rem / 2);\n}\n\n[dir=\"rtl\"] .target-pane_stage-selector-wrapper_qekSW {\n    margin-right: calc(0.5rem / 2);\n}\n", ""]);
// Exports
exports.locals = {
	"target-pane": "target-pane_target-pane_3S5E6",
	"targetPane": "target-pane_target-pane_3S5E6",
	"stage-selector-wrapper": "target-pane_stage-selector-wrapper_qekSW",
	"stageSelectorWrapper": "target-pane_stage-selector-wrapper_qekSW"
};
module.exports = exports;
