function getBufferFile (file) {
    if (!file) return null
    return new Promise((resolve, reject) => {
        // 创建FileReader对象
        const reader = new FileReader();
        // 读取文件
        reader.onload = function(event) {
        const fileContent = event.target.result;
        resolve(fileContent)
        };
        reader.readAsArrayBuffer(file);
    })
}
function getFileContent (file) {
    if (!file) return null
        return new Promise((resolve, reject) => {
        // 创建FileReader对象
        const reader = new FileReader();
        // 读取文件
        reader.onload = function(event) {
            const fileContent = event.target.result;
            const jsonData = JSON.parse(fileContent);
            resolve(jsonData)
        };
        reader.readAsText(file);
    })
}
// 将数组排序后比较是否相等
function arraysEqual (list, labels) {
    if (!list || !list.length) return false
    // 如果数组长度不一样，直接返回 false
    if (list.length !== labels.length) return false
    return JSON.stringify(list.sort()) === JSON.stringify(labels.sort())
}

module.exports = {
    getBufferFile,
    getFileContent,
    arraysEqual
}
