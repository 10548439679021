// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* #855CD6 */ /* 35% transparent version of looks-tertiary */ /* 15% transparent version of looks-tertiary */ /* #714EB6 */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #0B8E69 */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ body {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n} h2 {\n    font-size: 1.5rem;\n    font-weight: bold;\n} p {\n    font-size: 1rem;\n    line-height: 1.5em;\n} .crash-message_crash-wrapper_25B61 {\n    background-color: hsla(215, 100%, 65%, 1);\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n} .crash-message_body_1q0lu {\n    width: 35%;\n    color: white;\n    text-align: center;\n} /* scratch-www was overriding the colors */ .crash-message_body_1q0lu p, .crash-message_body_1q0lu h2 {\n    color: inherit;\n} .crash-message_reloadButton_FoS7x {\n    border: 1px solid hsla(215, 100%, 65%, 1);\n    border-radius: 0.25rem;\n    padding: 0.5rem 2rem;\n    background: white;\n    color: hsla(215, 100%, 65%, 1);\n    font-weight: bold;\n    font-size: 0.875rem;\n    cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"crash-wrapper": "crash-message_crash-wrapper_25B61",
	"crashWrapper": "crash-message_crash-wrapper_25B61",
	"body": "crash-message_body_1q0lu",
	"reloadButton": "crash-message_reloadButton_FoS7x"
};
module.exports = exports;
