export function object2query (queryObject) {
  return new URLSearchParams(Object.entries(queryObject)).toString()
}

export class Request {
  static post(url, data, options = {}) {
    return this.request(url, data, options, 'POST');
  }

  static get(url, data, options = {}) {
    return this.request(url, data, options, 'GET');
  }

  static async request(url, data, options, method) {
    const isFormData = data instanceof FormData;
    options.headers = options.headers || {};
    if (!isFormData) {
      options.headers["Content-Type"] = "application/json; charset=UTF-8";
    }

    if (typeof options.params === 'object') {
      url += `${url.includes('?') ? '&' : '?'}${object2query(options.params)}`;
      delete options.params;
    }

    const fetchOptions = {
      method,
      headers: { ...options.headers },
      ...(method === 'POST' && { body: isFormData ? data : JSON.stringify(data) })
    };

    const controller = new AbortController();
    const timeout = options.timeout || 300000;
    const timeoutId = setTimeout(() => controller.abort(), timeout);
    fetchOptions.signal = controller.signal;

    try {
      const response = await fetch(url, fetchOptions);
      clearTimeout(timeoutId);
      if (response.ok) {
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
          return await response.json();
        } else {
          return await response.blob();
        }
      }

    } catch (error) {
      if (error.name === 'AbortError') {
        throw new Error('Request timed out');
      }
      throw error;
    }
  }
}
