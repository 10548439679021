// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.watermark_sprite-image_3ywoR {\n    margin: auto;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    max-width: 48px;\n    max-height: 48px;\n    opacity: 0.35;\n}\n", ""]);
// Exports
exports.locals = {
	"sprite-image": "watermark_sprite-image_3ywoR",
	"spriteImage": "watermark_sprite-image_3ywoR"
};
module.exports = exports;
